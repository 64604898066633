import React from 'react';
import './App.css';

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <iframe 
          id="iframe" 
          src="https://sabina.canaraguas.es" 
          name="myiFrame" 
          scrolling="no" 
          allowFullScreen 
          title="Secure Remote Access"
          style={{
            overflow: 'hidden',
            margin: 0,
            padding: 0,
            height: '100vh',
            width: '100vw',
            border: 'none',
            background: 'none',
            position: 'absolute',
            boxSizing: 'border-box'  // Add this line
          }}
        />
      </div>
    );
  }
}

export default App;